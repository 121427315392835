import { createResizeObserver } from "@solid-primitives/resize-observer";
import { A, useLocation, useNavigate } from "@solidjs/router";
import { createEffect, createMemo, createSignal, on, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import logo from "../assets/img/logo.svg";
import ChangedIndicator from "./ChangedIndicator";
import { useDataStore } from "./DataStore";
import styles from "./Navbar.module.scss";
import { ThemeControllerButton } from "./ThemeController";

export default function Navbar() {
  const location = useLocation();

  const routeLocations = ["/", "/help", "/about"];
  const buttons: HTMLAnchorElement[] = [];
  const [linkContainer, setLinkContainer] = createSignal<HTMLDivElement>();
  const [indicatorEl, setIndicatorEl] = createSignal<HTMLDivElement>();
  const [isLoading, setIsLoading] = createSignal(true);

  const getPathIndex = createMemo(() => routeLocations.findIndex((ref) => ref.match(location.pathname)));

  // when page changes, transition navbar_indicator properties
  const [indicatorDirection, setIndicatorDirection] = createSignal(Direction.RIGHT);

  const updateTransition = (next: number, prev?: number) => {
    setIndicatorDirection((prev ?? next) < next ? Direction.RIGHT : Direction.LEFT);

    const el = indicatorEl();
    if (!el) throw new Error();

    el.style.transition = `${indicatorDirection() == Direction.LEFT ? "left" : "right"} 350ms cubic-bezier(1,0,.3,1) -140ms, ${indicatorDirection() == Direction.LEFT ? "right" : "left"} 350ms cubic-bezier(.75,0,.24,1) -40ms`;
  };

  const updateIndicator = (transition = true) => {
    const el = indicatorEl();
    if (!el) throw new Error();

    el.style.left = `${buttons[getPathIndex()]?.offsetLeft}px`;
    el.style.right = `${
      linkContainer()!.clientWidth - (buttons[getPathIndex()]?.offsetLeft + buttons[getPathIndex()]?.offsetWidth)
    }px`;

    if (!transition) el.style.transition = "none";
  };

  // Compute style on first run, after DOM is ready.
  onMount(() => {
    // eslint-disable-next-line solid/reactivity
    queueMicrotask(() => {
      setIsLoading(false);
      updateIndicator(false);
    });
  });

  // Compute style normally for subsequent runs.
  createEffect(
    on(getPathIndex, (index, prevIndex) => {
      if (prevIndex == undefined) return;

      updateTransition(index, prevIndex);
      updateIndicator();
    }),
  );

  createResizeObserver(linkContainer, () => {
    updateIndicator(false);
  });

  const navigate = useNavigate();
  const dataStore = useDataStore();

  return (
    <nav class={styles.navbar_sizer}>
      <div class={styles.navbar}>
        <div class={styles.title}>
          <A href="/" class="h-auto p-0 pr-1.5 font-600 hover:bg-neutral-100/70 btn dark:hover:bg-neutral-100/10">
            <img src={logo} alt="Concentrat.io logo" class="mr-2 h-8 w-8 rounded-md" />
            <span class="hidden xs:block">Concentrat.io</span>
            <ChangedIndicator show={dataStore.computeFormData.hasChanges} />
          </A>
        </div>
        <div
          class={styles.links}
          classList={{
            [styles.loading]: isLoading(),
          }}
          ref={setLinkContainer}
        >
          <A href="/" end activeClass={styles.active} ref={(e) => buttons.push(e)}>
            Compute
          </A>
          <A href="/help" activeClass={styles.active} ref={(e) => buttons.push(e)}>
            Help
          </A>
          <A href="/about" activeClass={styles.active} ref={(e) => buttons.push(e)}>
            About
          </A>
          <div
            class="absolute bottom-0 h-1 select-none rounded-full bg-primary-400 dark:bg-primary-500"
            ref={setIndicatorEl}
            style={{
              visibility: `${isServer ? "hidden" : "visible"}`,
            }}
          />
          <ThemeControllerButton />
        </div>
      </div>
    </nav>
  );
}

enum Direction {
  LEFT = "left",
  RIGHT = "right",
}
