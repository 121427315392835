import * as Sentry from "@sentry/solid";
import { solidRouterBrowserTracingIntegration } from "@sentry/solid/solidrouter";
import { mount, StartClient } from "@solidjs/start/client";

if (import.meta.env.DEV) {
  await import("solid-devtools");
}

Sentry.init({
  dsn: "https://eebcac18b5a906fce5e0685df6c0fd97@o4507745204895744.ingest.us.sentry.io/4507745211711488",
  integrations: [
    solidRouterBrowserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      showBranding: false,
      triggerLabel: "Give Feedback",
      formTitle: "Feedback/Bug Report",
      submitButtonLabel: "Submit Report",
      messagePlaceholder: "Do you have a suggestion or bug report? We would love to hear from you!",
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/concentrat\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

mount(() => <StartClient />, document.getElementById("app")!);
